var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{attrs:{"title":"Blacklist A Vehicle"}},[_c('template',{slot:"actions"},[_c('vs-button',{attrs:{"color":"dark"},on:{"click":_vm.handleBack}},[_vm._v("Back")])],1),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitBlacklist)}}},[_c('vs-row',{staticClass:"mb-base"},[_c('vs-col',{staticClass:"m-2",attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"center","vs-w":"2"}},[_c('span',[_vm._v("Registration Plate:")])]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"6"}},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-simple-suggest',{attrs:{"list":_vm.handleSearch,"display-attribute":"registrationPlate","value-attribute":"id","filter-by-query":true},on:{"select":_vm.setSelected},model:{value:(_vm.blacklist.registrationPlate),callback:function ($$v) {_vm.$set(_vm.blacklist, "registrationPlate", $$v)},expression:"blacklist.registrationPlate"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('vs-row',{staticClass:"mb-base"},[_c('vs-col',{staticClass:"m-2",attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"center","vs-w":"2"}},[_c('span',[_vm._v("Vehicle Model:")])]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"6"}},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"registrationPlate"},model:{value:(_vm.blacklist.vehicleModel),callback:function ($$v) {_vm.$set(_vm.blacklist, "vehicleModel", $$v)},expression:"blacklist.vehicleModel"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('vs-row',{staticClass:"mb-base"},[_c('vs-col',{staticClass:"m-2",attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"center","vs-w":"2"}},[_c('span',[_vm._v("Expiry Date:")])]),(!_vm.CheckExpired)?_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"3"}},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":{ required: true },"custom-messages":_vm.customMessages,"name":"ExpiryDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"w-full",attrs:{"name":"ExpiryDate","config":_vm.DateTimeConfig},model:{value:(_vm.blacklist.expiryDate),callback:function ($$v) {_vm.$set(_vm.blacklist, "expiryDate", $$v)},expression:"blacklist.expiryDate"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('vs-col',{staticClass:"m-2",attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"3"}},[_c('vs-checkbox',{staticClass:"inline-flex",model:{value:(_vm.CheckExpired),callback:function ($$v) {_vm.CheckExpired=$$v},expression:"CheckExpired"}},[_vm._v("Never Expires")])],1)],1),_c('vs-row',{staticClass:"mb-base"},[_c('vs-col',{staticClass:"m-2",attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"center","vs-w":"2"}},[_c('span',[_vm._v("Incident Date:")])]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"3"}},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":{ required: true },"custom-messages":_vm.customMessages,"name":"ExpiryDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"w-full",attrs:{"name":"IncidentDate","config":_vm.DateTimeConfig},model:{value:(_vm.blacklist.incidentDate),callback:function ($$v) {_vm.$set(_vm.blacklist, "incidentDate", $$v)},expression:"blacklist.incidentDate"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('vs-row',[_c('vs-col',{staticClass:"m-2",attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"center","vs-w":"2"}},[_c('span',[_vm._v("Reason:")])]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"6"}},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"Postcode","rules":{ regex: /^[a-zA-Z0-9 \\/-_',.-]+$/, required: true, max: 200 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"rows":"8"},model:{value:(_vm.blacklist.comments),callback:function ($$v) {_vm.$set(_vm.blacklist, "comments", $$v)},expression:"blacklist.comments"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('vs-divider'),_c('vs-row',[_c('vs-col',{staticClass:"m-2",attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"center","vs-w":"2"}},[_c('span')]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"3"}},[_c('vs-button',{staticClass:"mr-3 mb-2",on:{"click":function($event){$event.preventDefault();return _vm.submitBlacklist.apply(null, arguments)}}},[_vm._v("Submit")])],1)],1)],1)]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }