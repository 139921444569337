<template>
<vx-card title="Blacklist A Vehicle">
    <template slot="actions">
        <vs-button @click="handleBack" color="dark">Back</vs-button>
    </template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submitBlacklist)">

            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span>Registration Plate:</span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                    <ValidationProvider class="w-full" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                        <vue-simple-suggest :list="handleSearch" v-model="blacklist.registrationPlate" display-attribute="registrationPlate" value-attribute="id" :filter-by-query="true" @select="setSelected" />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
            </vs-row>

            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span>Vehicle Model:</span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                    <ValidationProvider class="w-full" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                        <vs-input class="w-full" name="registrationPlate" v-model="blacklist.vehicleModel" />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
            </vs-row>

            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span>Expiry Date:</span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" v-if="!CheckExpired">
                    <ValidationProvider class="w-full" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages" name="ExpiryDate">
                        <flat-pickr class="w-full" name="ExpiryDate" :config="DateTimeConfig" v-model="blacklist.expiryDate" />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                    <vs-checkbox class="inline-flex" v-model="CheckExpired">Never Expires</vs-checkbox>
                </vs-col>
            </vs-row>

            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span>Incident Date:</span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                    <ValidationProvider class="w-full" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages" name="ExpiryDate">
                        <flat-pickr class="w-full" name="IncidentDate" :config="DateTimeConfig" v-model="blacklist.incidentDate" />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
            </vs-row>

            <vs-row>
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span>Reason:</span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                    <ValidationProvider class="w-full" name="Postcode" :rules="{ regex: /^[a-zA-Z0-9 \\/-_',.-]+$/, required: true, max: 200 }" v-slot="{ errors }" :custom-messages="customMessages">
                        <vs-textarea class="w-full" rows="8" v-model="blacklist.comments" />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
            </vs-row>

            <vs-divider />

            <vs-row>
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span></span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                    <vs-button class="mr-3 mb-2" @click.prevent="submitBlacklist">Submit</vs-button>
                </vs-col>
            </vs-row>
        </form>
    </ValidationObserver>
</vx-card>
</template>

<script>
import {
    blacklistData
} from "../../store/api/blacklist"
import {
    customMessages
} from "../../filters/validationmessage";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'

import {
    carData
} from "../../store/api/car";

export default {

    data() {
        return {
            customMessages,
            blacklist: {},
            chosen: "",
            data: [],
            CheckExpired: false,
            pageNumber: 1,
            DateTimeConfig: {
                wrap: true,
                altFormat: "d-m-Y",
                altInput: true,
                allowInput: true,
                dateFormat: "Y-m-d",
                enableTime: false,
                time_24hr: false,
            },
            status: "Active"
        };
    },

    components: {
        flatPickr,
        VueSimpleSuggest
    },

    async created() {
        await this.handleSearch();
        await this.loadData();
    },

    methods: {
        setSelected(value) {
            this.blacklist.registrationPlate = value.registrationPlate
            this.blacklist.vehicleModel = value.model
        },
        async loadData() {
            if (this.$route.params.id) {
                this.blacklist = await blacklistData.getByKey(this.$route.params.id);
            }
        },
        async handleSearch() {
            if (this.blacklist.vehicleModel) {
                this.blacklist.vehicleModel = ""
            }
            const result = await carData.searchAllCarRegistration(this.status, this.chosen);
            return result;
        },
        async submitBlacklist() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            if (this.CheckExpired) {
                this.blacklist.expiryDate = null
            }

            this.blacklist.registrationPlate = this.blacklist.registrationPlate.toUpperCase()

            let apiResult = await blacklistData.createBlacklist(this.blacklist);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });

            this.$router.push("/admin/blacklist");
        },
        handleBack() {
            this.$router.back();
        }
    },
};
</script>

<style scoped>
.formfield {
    padding: 11px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
</style><style lang="scss">
#editForm {
    .vs-sidebar {
        position: fixed;
        z-index: 52000;
        min-width: 40vw;
        margin: 10px;
        max-height: 97vh;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    }
}
</style>
